import React from "react";
import { Heading1 } from "./Heading1";
import { Section } from "./Section";

/** Should page headers be shown if the caller does not explicitly set the visible prop? */
const defaultVisible = false;

interface PageHeaderProps {
  /** Primary header text */
  primary: string;
  /** Secondary header text */
  secondary: string;
  /** Override default visibility.  If not set, the default visibility will be used. */
  visible?: boolean;
}

/**
 * Display a main heading with accompanying text.  This is typically used just under the hero image.
 */
export const PageHeader: React.VFC<PageHeaderProps> = ({
  primary,
  secondary,
  visible,
}) => {
  const actualVisible = visible === undefined ? defaultVisible : visible;
  return !actualVisible ? null : (
    <Section>
      <Heading1>{primary}</Heading1>
      <p className="tos_main_txt">{secondary}</p>
    </Section>
  );
};
