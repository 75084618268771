import React from "react";
import { implementationOverviewImage, spaToHostImage } from "../images";
import { ExpandableImage } from "./ExpandableImage";
import { Heading2 } from "./Heading2";
import { Heading3 } from "./Heading3";
import { PageHeader } from "./PageHeader";
import { Paragraph } from "./Paragraph";
import { Section } from "./Section";

export const OurApproach: React.FC = () => {
  return (
    <>
      <PageHeader
        primary="Our Solution"
        secondary="Here's how we approach the modernization process."
      />
      <Section>
        <Heading2>The Twin Oak Approach</Heading2>
        <Paragraph>
          The Twin Oak approach uses automated translation to migrate your code
          and data to a modern platform. This approach allows us to preserve
          your existing data and the business rules in your existing code
          &mdash; both important business assets.
        </Paragraph>
        <Paragraph>
          We support these translated assets with specialized run-time libraries
          and build/deploy them with common devops automation. The devops
          automation should be familiar to most modern cloud developers. The
          run-time libraries allow us to generate code with a style and
          organization similar to your existing software, making it easy for
          your existing staff to understand and maintain the converted solution.
          It's still C# and you're still using modern development tools and
          running on a modern platform, but with some helpful guard rails to
          keep you on track and make your team feel at home.
        </Paragraph>
        <Paragraph>
          Once you are ready to write your own applications from scratch you can
          still use our data connection library to access your data. You can mix
          and match translated code with brand new code, accessing the same
          data, and choose the right balance for your development team and the
          rest of your business.
        </Paragraph>
        <Heading2>The Conversion Process</Heading2>
        <div className="tos_overview">
          <ExpandableImage
            src={implementationOverviewImage}
            className="tos_img_spa_diagram"
            thumbnailCaption="A simplified version of the translation process"
            expandedTitle="A simplified version of the translation process"
            expandedCaption="Your new system is built from our code libraries plus your translated code and translated data"
          />
          <Paragraph>
            We normally handle almost all of the conversion process for you.
            This is fairly straightforward and progresses roughly as follows:
          </Paragraph>
          <ul>
            <li>
              Data files, indexes, and catalogs are converted to a
              cloud-friendly format
            </li>
            <li>
              Programs are converted to C# classes that use our run-time program
              logic cycle (PLC) to interact with the converted data files
            </li>
            <li>
              Procedures are translated to interaction state machines to manage
              the step-by-step nature of running multiple programs and returning
              results
            </li>
            <li>
              Other digital assets are converted to JSON description files which
              are used by Twin Oak utilities to display menus, forms, DFUs, etc.
            </li>
            <li>
              The solution is published to an Azure cloud host including data
              files, version control, and an appropriate build pipeline. You
              have the option of publishing to a standard Windows IIS server.
            </li>
            <li>We work with you to QA the migrated solution.</li>
            <li>
              When you're ready, you take full ownership and control of your
              modern solution
            </li>
          </ul>
        </div>
        <Heading2>An Overview of Our Implementation</Heading2>

        <div className="tos_overview">
          <ExpandableImage
            src={spaToHostImage}
            className="tos_img_spa_diagram"
            thumbnailCaption="A simplified diagram of your new system's architecture"
            expandedTitle="A simplified  diagram of your new system's architecture"
            expandedCaption="Your new system uses a common modern architecture: 
            a client SPA interacting with backend web APIs"
          />
          <Paragraph>
            Our solution uses common web technologies that should be familiar to
            many modern developers. Our single-page application (SPA) client
            runs in a web browser. This SPA uses HTTP to communicate with
            several backend APIs on the server. These APIs are used to execute
            your translated procedures and programs.
          </Paragraph>
          <Paragraph>
            Twin Oak created several support libraries and a specialized
            Run-Time Engine that together enable your translated code to run in
            this environment. They provide data access, manage program and
            procedure state, enforce security, among other things.
          </Paragraph>
          <Paragraph>
            Many of the features like menus and forms of our SPA are dynamically
            generated from JSON files provided by our WebAPIs. These JSON files
            are initially translated from your original AS400 files and then are
            maintained on the your server from there on.
          </Paragraph>
          <Heading3>Client</Heading3>
          <Paragraph>
            Our client application is based on your Web Browser, React,
            Typescript
          </Paragraph>
          <Heading3>Server</Heading3>
          <Paragraph>
            Our server environment is based on C#, .Net Core, Web API, Azure web
            host, Azure File storage, Azure Blob storage, Azure Active
            Directory. If you have chosen to target an (on- or off-premises)
            windows server instead of Azure, then the hosting and storage are
            done by that server.
          </Paragraph>
          <Heading3>DevOps Pipeline</Heading3>
          <Paragraph>
            Our current solution provides web-based Azure pipelines to build,
            test, and deploy all of the code. In addition to the Azure tooling
            we include some YAML and powershell scripting. If you choose to host
            and run your solution outside of the cloud, our Azure pipeline can
            still be used to deploy your server.
          </Paragraph>
        </div>
      </Section>
    </>
  );
};
