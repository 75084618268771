import React from "react";
import { OurApproach } from "../components/OurApproach";
import { PageLayout } from "../components/PageLayout";
import { ourApproachPageHeroImage } from "../images";

const OurApproachPage: React.FC = () => {
  return (
    <PageLayout
      heroBackgroundImage={`url(${ourApproachPageHeroImage})`}
      heroOverlayLeadElement="How We Help You Modernize"
      heroOverlaySecondaryElement="A unique approach plus powerful tools"
    >
      <OurApproach />
    </PageLayout>
  );
};

export default OurApproachPage;
